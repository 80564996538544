<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  checked?: boolean | string[];
  value?: boolean | null;
  error?: string;
}

interface Emit {
  (event: 'update:checked', value: boolean | string[]): void;
}

const emit = defineEmits<Emit>();

const props = withDefaults(defineProps<Props>(), {
  checked: false,
  value: null,
  error: ''
});

const proxyChecked = computed({
  get: () => props.checked,
  set: (val) => emit('update:checked', val)
});
</script>

<template>
  <input
    v-model="proxyChecked"
    type="checkbox"
    :value="value"
    :class="error ? 'border-danger' : 'border-light-gray'"
    class="rounded text-primary shadow-sm focus:ring-primary"
  />
</template>
